import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'
import { MdLocationOn } from 'react-icons/md';
import axios from 'axios';

const Home = () => {
    const [experiences, setExperiences] = useState(null);
    const [userName, setUserName] = useState('Unknown');
    const { userId, city, sharedName, shareId } = useParams();

    useEffect(() => {
        console.log(userId, city, shareId, sharedName)
        const mySharedExpId = shareId?.split(':')[1]?.split(',');
        if (!Boolean(city)) {
            const myfun = async () => {
                if(userId?.length > 0){
                    // const newUrl = `https://tinyurl.com/${userId}`;
                    // window.location.replace(newUrl);
                    // window.history.pushState(null, null, newUrl);
                    let fetchShorlUrl =  `https://api.goertravelrecs.com/api/expand?short_url=${userId}`
                    const response = await axios.get(fetchShorlUrl, {
                        headers: { 'Content-Type': 'application/json', 'Accept': '*/*' }
                    });
                    console.log(response);
                    if(response.data?.long_url){
                        const newUrl = response.data?.long_url;
                        window.location.replace(newUrl);
                        // window.history.pushState(null, null, newUrl);
                    }
                }
            }
            myfun();
        }
        else {
            const fetchData = async () => {
                try {
                    const body = {
                        userid: userId
                    }
                    const findExperiencesByCity = (cityName, data) => {
                        // console.log('city name is', cityName);
                        const experiences = [];
                        for (const country in data) {
                            const cities = data[country];
                            for (const city in cities) {
                                if (city === cityName) {
                                    // experiences.push(...cities[city]);
                                    // console.log(cities[city])
                                    const mysharecitiesAllExp = cities[city];
                                    mysharecitiesAllExp.map(exp => {
                                            experiences.push(exp);
                                    })
                                }
                            }
                        }
                        console.log(experiences)
                        return experiences;
                    };

                    const baseUrl = 'https://api.goertravelrecs.com/api/user/';
                    // const baseUrl = 'http://35.166.120.41:3001/api/user/';

                    const fullUrlExpDetails = baseUrl + 'fetchexperiences';
                    const fullUrlUsrDetails = baseUrl + 'fetchUserDetails';

                    const response = await axios.post(fullUrlExpDetails, JSON.stringify(body), {
                        headers: { 'Content-Type': 'application/json', 'Accept': '*/*' }
                    });
                    const userDetailsResponse = await axios.post(fullUrlUsrDetails, JSON.stringify(body), {
                        headers: { 'Content-Type': 'application/json', 'Accept': '*/*' }
                    });
                    // console.log(userDetailsResponse.data.name)
                    setUserName(userDetailsResponse.data.name);
                    // console.log(response.data)
                    const myCitiesArray = findExperiencesByCity(city, response.data);
                    // console.log(myCitiesArray)
                    setExperiences(myCitiesArray);
                } catch (error) {
                    console.log('Error:', error);
                }
            };

            fetchData();
        }
    }, []);

    const adderCity = (city) => {
        console.log('city name is', city.split(' ').join('+'));
        return city.split(' ').join('+');
    }



    return (
        <>
            <div style={{ padding: '20px 20px 60px 20px' }}>
                {experiences ? (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
                            <MdLocationOn size={20} />
                            <h4>{experiences[0]?.location?.city + ', ' + experiences[0]?.location?.country}</h4>
                        </div>
                        <h4 style={{ color: '#a2cfe8', textAlign: 'center' }}>{userName?.split(' ')[0]}'s Experiences in {experiences[0]?.location?.city + ', ' + experiences[0]?.location?.country} {sharedName ? `for ${sharedName}` : ''}</h4>
                        <div style={{ gap: 20, display: 'flex', flexDirection: 'column' }}>
                            {experiences.map((experience, index1) =>
                                <div style={{ background: '#e1eff7', padding: 10 }}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                        {experience.category.map((category) => <div style={{ fontSize: 14, color: '#444444', border: '1px solid #444444', padding: '2px 4px', borderRadius: '4px' }}>{category}</div>)}
                                    </div>
                                    <div style={{ display: 'flex', gap: 10, marginTop: '10px' }}>
                                        <div style={{ height: '100px', overflow: 'hidden', borderRadius: 10, maxWidth: '50%' }}>
                                            <Carousel swipeable={true} style={{ overflow: 'hidden' }} showStatus={false} showArrows={false} showIndicator={false}>
                                                {experience.images.map((url, index) =>
                                                    <div>
                                                        <img key={index} src={url} alt='images' style={{ height: '100px', objectFit: 'cover' }} />
                                                    </div>
                                                )}
                                            </Carousel>
                                        </div>
                                        <div key={index1} style={{ display: 'flex', gap: 10, borderRadius: 8 }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                                <div>
                                                    <a href={experience?.location?.link} target='_blank' style={{ fontWeight: 400, fontSize: 14, color: 'blue', textDecoration: 'underline', textDecorationThickness: '1px', textUnderlineOffset: 4 }}>{experience.name}</a>
                                                    <div style={{ fontWeight: '400', fontSize: 12, color: '#444444', marginTop: '6px' }}>{experience.desc}</div>
                                                </div>
                                                {/* <div style={{ backgroundColor: '#FFF9EA', padding: '0px 5px 6px 5px', borderRadius: '5px', fontWeight: 600, alignSelf: 'flex-end' }}>{experience.category}</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <div>
                        {!Boolean(city) && <p>Loading...</p>}
                    </div>
                )}

            </div>
            <div style={{ color: 'white', backgroundColor: '#1e3b1a', textAlign: 'center', padding: '5px', position: 'fixed', bottom: 0, width: '100%' }}>
                <div>Powered by GOER</div>
                <div style={{ fontSize: 12 }}>Don't see what you are looking for? Head to <a href='https://www.goertravelexperiences.com/' style={{color: 'white'}}>goertravelexperiences.com</a> for bespoke recommendations in every city!</div>
            </div>
        </>
    );
};

export default Home;
