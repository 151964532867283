import React, { useEffect } from 'react';

const ForgetPassword = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('token');
    const redirectToDifferentURL = () => {
      const redirectURL = `goer://forgotpassword?token=${id}`;
      window.location.href = redirectURL;
    };
    redirectToDifferentURL();
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
      <h1 style={{ margin: 'auto', textAlign: 'center' }}>Redirecting...</h1>
    </div>
  );
};

export default ForgetPassword;
