import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleMap = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lat = searchParams.get('lat');
  const lng = searchParams.get('lng');

  useEffect(() => {
    let map;

    const loadGoogleMapsAPI = () => {
      return new Promise((resolve) => {
        if (window.google && window.google.maps) {
          resolve();
        } else {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBLh_AX7Osn5Q-N2EiDX9XLTdWxAP3NKl0&callback=initMap`;
          script.async = true;
          script.defer = true;
          window.initMap = resolve;
          document.head.appendChild(script);
        }
      });
    };

    const initMap = () => {
      const myLatlng = { lat: parseFloat(lat), lng: parseFloat(lng) };
      map = new window.google.maps.Map(document.getElementById('map'), {
        zoom: 15,
        center: myLatlng,
      });

      new window.google.maps.Marker({
        position: myLatlng,
        map: map,
      });
    };

    loadGoogleMapsAPI().then(() => {
      initMap();
    });

    return () => {
      // Cleanup: Remove the global `initMap` function and delete the Google Maps API script
      delete window.initMap;
      const script = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]');
      if (script) {
        script.remove();
      }
    };
  }, [lat, lng]);

  return <div id="map" style={{ width: '100%', height: '100vh' }}></div>;
};

export default GoogleMap;
