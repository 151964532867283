import React from 'react';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import ForgetPassword from './ForgetPassword';
import Home from './Home';
import GoogleMap from './GoogleMap';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/:userId/:city/:shareId" element={<Home/>} />
        <Route exact path="/:userId/:city/:sharedName/:shareId" element={<Home/>} />
        <Route exact path="/:userId" element={<Home/>} />
        <Route path="/" element={<Home/>} />
        <Route path="/forgetpassword" element={<ForgetPassword/>} />
        <Route path="/googleMap" element={<GoogleMap/>} />
      </Routes>
    </Router>
  );
};

export default App;
